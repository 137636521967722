* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}


body {
    height: 100vh;
    margin: 0;

    // color: var(--text-base);
    font-size: 14px;
    // "font-family" defined in separate .scss

    @include unselectable;
}


:focus {
    outline: none; //anti Google Chrome Version 86.0.4240.183 "feature"
}