:root {
    --color-white: #ffffff;
    --color-dark : #11142d;

    --color-iri-dark : #0b3b7a;
    --color-iri-light: #a4eee7;

    --color-iri-orange      : #D3A518;
    --color-iri-orange-hover: #e6b31b;
}


$color-iri-dark: #0b3b7a;  //var(--color-blue-iri-dark);