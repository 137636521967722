.root {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--color-iri-dark);
    padding-bottom: 50px;
}

.formWrp {
    // background: red;
    display: flex;
    align-items: center;
    width: 250px;
}

.form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: absolute;
    // padding-top: 20px;

    opacity: 1;
    transition: opacity 0.9s;

    &.hidden {
        opacity: 0;
        z-index: -1;
    }

    .errMsg {
        // order: -1;
        position: absolute;
        top: -32px;
        width: 100%;
        // margin: 20px 0;
        // color: #ff8181;
        color: #fcb1b1;
        font-size: 16px;
        text-align: center;

        z-index: 2;
        background: darkred;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0px 0px 18px 4px #5f2828;

        padding-right: 20px;

        >svg {
            position: absolute;
            color: whitesmoke;
            right: 0;
            margin-right: 5px;
            top: 5px;
        }
    }

    >label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        background-color: whitesmoke;

        border: solid 1px #132845ad;
        overflow: hidden;

        input {
            background-color: transparent;
            border: none;
            padding: 10px;
            padding-left: 40px;
            padding-right: 32px;
            font-size: 1.1rem;

            &::placeholder {
                font-size: .8rem;
                opacity: .5;
            }
        }

        .eye {
            display: flex;
            position: absolute;
            right: 4px;
            cursor: pointer;

            >svg {
                height: 18px;
                stroke: var(--color-dark);
                transition: all .3s;

                &:first-of-type {
                    position: absolute;
                }

                &:global(.visible) {
                    stroke: transparent;
                    // background-color: red;
                }
            }
        }
    }

    >button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        background-color: var(--color-iri-orange);
        color: var(--color-dark);
        border: none;
        padding: 10px 7px;
        font-weight: 600;
        font-size: 1.15rem;
        cursor: pointer;

        &:hover {
            background-color: var(--color-iri-orange-hover);
        }
    }

    >label,
    >button,
    .btnGoogle {
        position: relative;
        border-radius: 6px !important;
        transition: all .3s;
        $bsc: #3467aa91;
        box-shadow:
            -2px -2px 6px 0px $bsc,
            2px 2px 6px 0px $bsc,
            2px -2px 6px 0px $bsc,
            -2px 2px 6px 0px $bsc !important;

        >svg {
            position: absolute;
            left: 0;
            margin-left: 7px;
            height: 1.25rem;
            width: 1.35rem;
            transition: all .3s;
        }

        &:focus-within {
            border-color: transparent;
            $bsc: #a4eee77b;
            box-shadow:
                -2px -2px 4px 0px $bsc,
                2px 2px 4px 0px $bsc,
                2px -2px 4px 0px $bsc,
                -2px 2px 4px 0px $bsc;

            >svg {
                // stroke: #a4eee7;
                stroke: var(--color-iri-dark);
                height: 1.75rem;
                width: 1.55rem;
            }
        }
    }

    >button:focus-within>svg {
        stroke: var(--color-iri-light);
        opacity: .8;
    }

    .modeSwitch{
        align-self: center;
        width: fit-content;
        margin-top: 16px;
        font-size: 15px;
        color: rgb(230, 234, 255);
        
        display: flex;
        align-items: baseline;
        
        >span {
            margin-left: 8px;
            font-size: 16px;
            // font-weight: 600;
            cursor: pointer;

            color:var(--color-iri-orange);
            transition: color .3s;

            &:hover {
                color:var(--color-iri-orange-hover);
            }
        } 
    }

    .separator {
        $sep: rgba(245, 245, 245, 0.542);
        color: $sep;
        border-top: solid 1px $sep;
        border-bottom: solid 1px $sep;
        border-radius: 4px;
        padding: 6px 16px;
        margin: 20px;
        margin-top: 25px;
        width: fit-content;
        align-self: center;
    }

    .btnGoogle {
        color: var(--color-dark) !important;
        font-weight: bold !important;
    }
}


@media (max-width: 768px) {
    .root {
        flex-direction: column;
    }

    .logo {
        position: relative;
        top: -50px;
    }

    .formWrp {
        flex-direction: column;
        height: 50vh;
        position: relative;
        top: -100px;
    }

    .form {
        // position: relative;
        // top: -50px;
    }
}