.topbar {
    position: fixed;
    width: 100%;
    top: 0;
    height: 48px;
}

.main {
    padding-top: 55px;
    min-height: 100vh;
    background-color: #fafafa;
}