.nav {
    background-color: var(--color-iri-dark);
    opacity: .98;
    z-index: 999;
    // box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
        height: 26px;
        margin-top: 2px;
        margin-left: 10px;
    }

    .navMenu {
        flex-grow: 1;
    }

    .logOut {
        height: 20px;
        stroke: whitesmoke;
        stroke-width: 2.15;
        margin-right: 10px;
        cursor: pointer;

        transition: stroke-width .3s;

        &:hover {
            stroke-width: 2.75;
        }
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: whitesmoke;
        font-size: 1.15rem;

        transition: all .2s;

        &:hover {
            opacity: .9;
        }

        margin-right: 20px;
        padding: 4px 5px;
    }
}