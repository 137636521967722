@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Oxygen&family=Ubuntu&display=swap');

// titles: oxygen/fira sans*
// texts: ubuntu

html,
body {
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        // "Oxygen",
        "Ubuntu",
        // "Fira Sans",
        // "Roboto",
        // "Oxygen",
        // "Segoe UI",
        // "Cantarell",
        // "Droid Sans",
        // "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
    font-family: "Fira Sans";
}

button,
input {
    font-family: inherit; //anti default "Arial"
}